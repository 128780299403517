@keyframes ssss {
  0% {
    opacity: .6;
  }
  50% {
    opacity: .8;
  }
  100% {
    opacity: .6;
  }
}

.captcha {
  position: relative;
  background: #ccc center no-repeat;
  background-size: 110% 110%;
  cursor: pointer;

  img {
    transition: .3s ease-in-out;
    position: absolute;
    width: 0;
    box-shadow: #000 0 10px 20px -10px;
    border-top: 10px solid transparent;
    border-radius: 4px;
    opacity: 0;
  }

  &:hover {
    img {
      top: 100%;
      width: 200px;
      opacity: 1;
    }
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #fff;
    opacity: 0;
    transition: .3s ease-in-out;
  }

  &.ld {
    cursor: auto;
    &:before {
      opacity: .6;
      animation: ssss 2s infinite;
    }
  }
}
