@import "../../styles/util";
@import "../../styles/anim";

$hoverLeftColor: #448af5;
.dashboard {
  background: #f1f1f5;
  position: relative;
  background-size: cover;
  padding-bottom: 20px;
  padding-top: 1px;
  display: flex;
  flex-direction: column;
  flex: 1;
  .r {
    display: flex;
    align-items: center;
  }

  .ma {
    padding: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &:after {
    content: '';
    clear: both;
    display: block;
  }

  .d-container {
    min-height: 418px;
    margin: 10px;
    position: relative;
    overflow: visible;
    border-radius: 12px;
    background-size: cover;
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .mu-l {
    background: #f2f2f2;
    display: flex;
    color: #fff;
    position: relative;
    z-index: 3;
    &+.mu-r{
      .dash-title{
        h1{
          background: none;
          color: #333;
          font-size: 24px;
          margin: 20px 0 10px;
        }
      }
    }
    .mus {
      display: flex;
      margin-bottom: -5px;
      .mu{
        border-radius: 6px 6px 0 0;
        &:hover{
          background: #f7f7f7;
        }
        &.act {
          background: #fff;
        }
      }

      .mu_span {
        display: flex;
        font-size: 14px;
        color: #888;

        i {
          display: none;
        }
      }
    }

    .hd {
    }

    .mx {
      display: flex;
      padding: 0 10px;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 14px;
        margin: 0 5px;
        flex: 1;
        max-width: 80px;
        height: 38px;
        font-size: 16px;
        line-height: 22px;
        color: #ffffff;
        border: 1px solid #3d4663;
        box-sizing: border-box;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.2s linear;

        &:hover {
          background: rgba(226, 234, 245, 0.5);
        }
      }
    }

    .wall {
      margin-bottom: 10px;
      font-size: 13px;
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      .w {
        padding-left: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      i, s {
        display: block;
        margin: 0 5px;
        background: url("./img/0.png") center no-repeat;
        width: 13px;
        height: 13px;
      }

      s {
        background-image: url("./img/1.png");
        cursor: pointer;
        opacity: .6;

        &:hover {
          opacity: 1;
        }

        &.ld {
          pointer-events: none;
          animation: rotateZ360 .6s linear both infinite;
        }
      }
    }
  }

  .main-page {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .mu-r {
    border-radius: 10px;
    background: #fff;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .mu_span {
    padding: 0 15px;
    white-space: nowrap;
    display: block;
    line-height: 46px;
    border-radius: 6px;
  }

  .slide-mu-r {
    border-radius: 10px;
    position: absolute;
    left: 180px;
    top: 0;
    bottom: 0;
    width: 160px;
    z-index: 99;
    background: rgba(255, 255, 255, 0.85);
    padding: 20px 0 !important;
    box-shadow: rgba(0, 0, 0, 0.5) 6px 4px 28px -14px;
    display: none;

    .mu_span {
      padding: 0 20px;
    }
  }

  .actUl {
    display: block !important;
  }


  .us {
    height: 240px;
    margin-bottom: 10px;
    position: relative;

    label {
      text-align: center;
      display: block;
      font-size: 13px;
      padding: 0 0 20px;

      b {
        font-weight: 400;
      }

      .re {
        color: red;
      }

      .ro {
        color: orange;
      }

      .gr {
        color: #0c9264;
      }
    }

    .ics {
      display: flex;
      margin: 0 auto;
      width: 180px;
      padding: 20px 10px;
      justify-content: space-between;
      border-bottom: 1px solid transparentize(white, .8);

      i {
        height: 24px;
        width: 24px;
        display: flex;
        opacity: .3;
        background: center no-repeat;
        background-size: 70% auto;
        border-radius: 50%;
        position: relative;
        font-style: normal;

        div {
          position: absolute;
          display: none;
          white-space: nowrap;
          padding: 0 20px;
          width: auto;
          height: 32px;
          background: rgba(163, 166, 168, 0.6);
          box-shadow: 4px 3px 8px -2px rgba(136, 136, 136, 0.3);
          border-radius: 6px;
          color: #fff;
          font-size: 13px;
        }

        &:hover {
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translate3d(-50%, 10px, 0);

            &:before {
              content: '';
              border: 7px solid transparent;
              border-top: 0;
              border-bottom-color: rgba(163, 166, 168, 0.6);
              position: absolute;
              top: -7px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }

      .act {
        opacity: .7;
      }

      .a {
        background-image: url("../../assets/images/new/22.png");

        div {
          margin-left: 90px;

          &:before {
            margin-left: -90px;
          }
        }
      }

      .b {
        background-image: url("../../assets/images/new/23.png");
      }

      .c {
        background-image: url("../../assets/images/new/31.png");
      }

      .d {
        background-image: url("../../assets/images/new/30.png");
      }
    }

    .cash {
      display: flex;
      color: #fff;
      justify-content: center;
      margin: 0 0 10px;
      align-items: center;

      span {
        color: #448af5;
        font-weight: bold;
      }
    }

    .h {
      position: relative;
      display: flex;
      height: 159px;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .img {
        width: 77px;
        height: 77px;
        margin: 23px 0 13px;
        background: center no-repeat;
        background-size: contain;
        cursor: pointer;
        border-radius: 50%;
      }
    }

    .us-name {
      font-size: 14px;
      line-height: 14px;
      text-align: left;
      color: #fff;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;

      .v {
        width: 34px;
        height: 16px;
        border-radius: 7px;
        background: linear-gradient(-11deg, #036CEA, #84BEF8);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        margin-right: 5px;

        &.u0 {
          background: linear-gradient(-11deg, #ea8301, #fac729);
        }

        &.u2 {
          background: linear-gradient(-11deg, #00802b, #1dd771);
        }

        &.u1 {
          background: linear-gradient(-11deg, #7b13a7, #ba84f8);
        }
      }
    }

    .us-des {
      width: 77px;
      height: 16px;
      margin-top: 5px;
      background: pxUrl("us-des.png");
    }
  }


  .dash-main {
    background: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.2) 0 4px 14px -8;
    flex: 1;
    position: relative;
    min-height: 670px;
    overflow: auto;
    font-size: 13px;
    padding: 30px;

    input, .x-select {
      border: 1px solid rgba(0, 0, 0, .05);
      border-radius: 3px;
      margin-right: 10px;
      height: 40px;
      display: flex;
      align-items: center;
    }

  }

  .xt {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0;
    border-radius: 0;
    background: none;
    box-shadow: none;
    overflow: visible;
  }
}

.col-line {
  background: #f0f0f0;
  //background: url("./img/l.png") no-repeat;
  height: 1px;
  width: 100%;
  background-size: 100%;
}

.x-tab-a {
  display: flex;
  align-items: center;

  div {
    height: 43px;
    width: 117px;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #fff;
    background: #e1e5ec;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    margin-right: 10px;

    &:after {
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 4px;
      background: #fff;
      position: absolute;
      right: 5px;
      top: 5px;
    }

    &.act {
      background: #2b81ff;
      pointer-events: none;
    }
  }
}

.dashboard .dash-main {
  .incPage, .divPage {
    .x-select {
      width: auto;
      min-width: 140px;
      padding: 0 20px 0 5px;

      .opts {
        left: auto;
      }
    }
  }
}
