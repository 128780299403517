.trans-card{
  width: 262px;
  height: 291px;
  background: #fff;
  width: 100%;
  [disabled]{
    pointer-events: none;
  }
  button{
    &:hover{
      opacity: 1;
    }
    transition: .2s;
    opacity: .7;
    margin: 30px;
    background: #1e5adc;
    border-radius: 6px;
    color: #fff;
    width: 300px;
    height: 40px;
    display: flex;
   align-items: center;
    justify-content: center;
    border: none;
  }
  .c{
    padding: 30px;
    form{
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 60px;
    }
    .x-ipt{
      flex: 1;
      width: auto;
      .ipt{
        border-radius: 6px;
      }
    }
  }
  .a{
    align-items: center;
    color: #fff;
    height: 81px;
    display: flex;
    background: #ff818c;
    border-radius: 8px;
    label{
      font-size: 12px;
      padding-bottom: 5px;
      opacity: .7;
    }
    h1{
      font-weight: 200;
      font-size: 24px;
    }
    b{
      margin: 10px;
      border-radius: 8px;
     align-items: center;
      justify-content: center;
      font-size: 30px;
      font-weight: 200;
      display: flex;
      width: 58px;
      height: 58px;
      background: #fea2aa;
    }
  }
}
