.activeWin {
  .popup-win{
    overflow: auto;
    border-radius: 10px!important;
    display: flex;
    flex-direction: column;
    padding: 4px!important;
    .close{
      right: 3px;
      top: 3px;
    }
  }
  table{
    border-radius: 10px;
    .firstRow{
    td{
      font-weight: 400;
    }
      text-align: center;
      padding: 10px 0;
      background: #64bdf6;
      color: #fff;
    }
    background: #f7f7f7;
    min-width: 90%;
    max-width: 100%;
    border-collapse: collapse;
    th,td{
      padding: 5px 0;
      font-size: 13px;
      text-align: center;
      border: 1px solid #eee;
    }
  }
   h3{
     font-weight: 200;
     font-size: 24px;
     padding: 20px;
     text-align: center;
   }
  pre {
    font-size: 14px;
    line-height: 2;
    white-space: pre-wrap;
    padding: 20px;
    overflow: auto;
    flex: 1;
  }

  img {
    border-radius: 10px;
    margin: 20px;
  }
}

.pr-hd {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  margin: 0 auto;

  &:after {
    background: url(../../assets/images/new/a/26.png);
    width: 178px;
    height: 113px;
    right: -178px;
    bottom: 50px;
  }

  .cs-v {
    background: rgba(200, 200, 200, .2);
  }

  .hd-banner {
    width: 100%;
    border-radius: 10px;
  }
}
.full-page.pro{
  background: #ececec;
}
#promoList {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 1240px;
  min-height: 944px;
  border-radius: 10px;
  margin: 10px auto 95px;

  .x-pg {
    margin: 20px 30px;
  }

  .tbs {
    padding: 40px 20px;
    display: flex;
    height: 62px;
    justify-content: space-between;

    i {
      height: 18px;
      width: 18px;
      display: block;
      margin-right: 10px;
      background: center no-repeat;
      background-size: contain;
    }

    .z0 {
      background-image: url("./img/0.png");
    }

    .z1 {
      background-image: url("./img/1.png");
    }

    .z2 {
      background-image: url("./img/2.png");
    }

    .z3 {
      background-image: url("./img/3.png");
    }

    .z4 {
      background-image: url("./img/4.png");
    }

    .z5 {
      background-image: url("./img/5.png");
    }

    div {
      & + div {
        margin-left: 20px;
      }

      font-size: 14px;
      padding: 0 30px;
      min-width: 103px;
      max-width: 180px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      border-radius: 40px;
      border: 1px rgb(205, 214, 224) solid;
      background-color: rgb(255, 255, 255);
      height: 42px;

      &:hover, &.act {
        color: #fff;
        border-color: #1e5adc;
        background: linear-gradient(#346eec, #1e5adc);

        .z0 {
          background-image: url("./img/01.png");
        }

        .z1 {
          background-image: url("./img/11.png");
        }

        .z2 {
          background-image: url("./img/21.png");
        }

        .z3 {
          background-image: url("./img/31.png");
        }

        .z4 {
          background-image: url("./img/41.png");
        }

        .z5 {
          background-image: url("./img/51.png");
        }
      }

      &:hover {
        background: linear-gradient(#1e5adc,#346eec);
      }
    }
  }

  .ls {
    margin: 20px;
    flex: 1;
  }

  & > div:not(.x-pg) > i {
    display: block;
    width: 100%;
    border-radius: 5px;
    height: 200px;
    background: url(../../assets/images/new/a/promotions.png) center no-repeat;
    background-size: cover
  }

  .pr-card {
    position: relative;

    & > .btn {
      left: 40px;
      top: 140px;
      z-index: 2;
    }

    .link {
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        color: #00A2FF;
      }
    }

    &.no {
      min-height: 0;
    }

    float: left;
    margin: 10px 0;
    min-height: 200px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #e6e6e6;
    -webkit-transition: .1s ease-in-out;
    transition: .1s ease-in-out;
    cursor: pointer;

    .t {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      padding: 0 20px;
    }

    .btn {
      font-size: 14px;
      background-image: linear-gradient(rgb(97, 196, 254), rgb(46, 162, 254));
      box-shadow: 0 4px 6px 0 rgba(53, 169, 251, 0.35);
      width: 140px;
      height: 38px;
      transition: .3s ease-in-out;
      display: flex;
      color: #fff;
      align-items: center;
      justify-content: center;
      border-radius: 40px;
      position: absolute;

      &.hi {
        opacity: 0;
        pointer-events: none;
      }

      &:hover {
        background: linear-gradient(#6cc9fd, #5693fd);
      }
    }

    .pic {
      display: block;
      height: 200px;
      border-radius: 8px;
      background: #f5f5f5 url(../../assets/images/new/a/25.png) center no-repeat;
      background-size: cover;
      transition: .3s ease-in-out
    }

    & > h3 {
      margin: 18px 22px 8px;
      font-size: 18px;
      color: #666
    }

    &:hover {
      box-shadow: rgba(48, 169, 254, .2) 0 10px 20px -8px
    }

    .ctx {
      overflow: hidden;
      max-height: 0;
      position: relative;
      transition: .2s ease-in-out;
      padding: 0 30px 20px;

      pre {
        white-space: pre-wrap;

        & + .btn {
          right: 20px;
          bottom: 20px;
        }
      }
    }
  }
}
