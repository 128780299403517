* {
  font-family:  "Microsoft Yahei", sans-serif, arial;
  box-sizing: border-box;
}
li {
  list-style: none;
}
body, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, button, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

body, html, #root {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  color: #555;
}

a {
  color: #484848;
  text-decoration: none;
}

a:hover {
  color: #55c3fb;
}

a.disable {
  color: #999;
}

a.disable:hover {
  text-decoration: none;
  cursor: default;
}


.input {
  width: 268px;
  height: 26px;
  padding: 0 5px;
  border: 1px solid #C1BBC7;
  line-height: 26px;
  font-size: 12px;
  color: #535471;
  background: #FFFFFF;
  border-radius: 2px;
  transition: all .2s;
}

.input:hover {
  border-color: #26B999;
  box-shadow: 0 0 3px #26B999;
}

.input:focus:hover {
  border-color: #A9A9B8;
  color: #555;
  box-shadow: 0 1px 3px rgba(120, 198, 116, 0.2) inset;
}

.input.err, .input.err:hover {
  border-color: #FF819C;
  background-color: #FFF7F9;
  box-shadow: 0 1px 3px rgba(255, 129, 156, 0.2) inset;
}

.input:focus {
  border-color: #A9A9B8;
  color: #555;
  box-shadow: 0 1px 3px rgba(169, 169, 184, .5) inset;
  outline: none;
}


@import "../assets/font/stylesheet.css";
@import "./anim";

@mixin _scroll() {
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #071a3f;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #0d1828;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #1c2c57;
  }
}

#root {
  z-index: 1;
  padding: .1px 0;
  position: relative;
  transition: background .3s ease-in-out;
  min-width: 1240px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

* {
  @include _scroll();
}

.container {
  width: 1240px;
  margin: 0 auto;
}

.x-loading {
  z-index: 10;
  &.fixed {
    position: fixed;
    z-index: 100;
  }

  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  display: none;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  align-items: center!important;
  justify-content: center!important;
  background: rgba(255, 255, 255, 0.5) !important;

  &.a, &.b {
    display: flex;
  }

  &.a.b {
    opacity: 1;
  }

  .in {
    min-width: 200px;
    min-height: 70px;
    padding: 20px;
    display: inline-flex;
    background: #fff;
    align-items: center;
    box-shadow: rgba(0, 0, 0, .2) 0 10px 20px;
    border-radius: 3px;
    position: relative;
    top: -5%;
    justify-content: center;

    & > div:first-child {
      i {
        background: none;
        display: block;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        border: 2px solid #0A94E3;
        border-right-color: transparent;
        border-bottom-color: transparent;
        margin-right: 12px;
        animation: rotateZ360 .5s .2s linear both infinite;

        i {
          position: relative;
          top: -2px;
          left: -2px;
          margin: 0;
          animation: rotateZ-360 1.3s linear both infinite;
        }
      }
    }
  }
}

table.report-table {
  color: #666;
  width: 100%;
  border-collapse: collapse;
  position: relative;
  thead {
    transform: translate3d(0,0,0);
    border: 1px solid #dedede;
    position: relative;
    z-index: 0;
  }

  a {
    padding: 0 5px;
  }
  tbody{
    position: relative;
    z-index: 3;
    tr:hover {
      position: relative;
      z-index: 3;
      background: #448af5;
      transform: translate3d(0,0,10px);
      box-shadow: #448af5 0 2px 18px;
      outline: #448af5 solid 10px;
      border-color: #346df5;
      td{
        color: #fff;
        border-top: 1px solid #448af5;
        a{
          color: #fff;
        }
      }
    }
  }

  tr + tr {
    border-top: 1px dashed #dedede;
  }

  td {
    text-align: center;
    height: 50px;

    & > div {
      height: inherit;
      display: flex;
      white-space: nowrap;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }
  }

  th {
    height: 52px;
    color: #262732;
    background-color: #f4f7f8;
    padding: 10px 0;
    font-weight: 400;

    & + th {
      position: relative;

      &:before {
        position: absolute;
        left: 1px;
        top: 20px;
        height: 10px;
        content: '';
        display: block;
        width: 1px;
        background: #dadada;
      }
    }
  }
}

.full-page{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  &>.full{
    flex: 1;
  }
}


