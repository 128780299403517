.phone_need {
  margin: 200px 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  .tip {
    span {
      padding: 0 3px;
      cursor: pointer;
      text-decoration: underline;
    }

    font-size: 13px;
    padding: 5px 20px;
    border-radius: 4px;
    background: #f8e8e8;
    color: red;
  }
}

.tr-result{
 position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &>span{
    display: flex;
    align-items: center;
    justify-content: center;
    margin:  10px 0 50px;
    cursor: pointer;
    color: #377ef6;
    i{
      display: block;
      background: url("./img/s.svg") no-repeat center;
      background-size: 20px auto;
      width: 20px;
      height: 20px;
    }
    &:hover{
      text-decoration: underline;
    }
  }
  &>i{
    background: url("./img/g.png");
    width: 482px;
    height: 89px;
    display: block;
  }
  .r{
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    div{
      padding-left: 20px;
      height: 26px;
      flex: 1;
      display: flex;
      align-items: center;
      label{
        width: 70px;
      }
    }
  }
  .inf{
    margin: 40px 0 30px;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 137px;
    width: 795px;
    background: #f0f6fe;
    border-left: 3px solid #3281f6;
  }
}
.trans-page.x-sheet {
  position: relative;
   font-size: 13px;
  .rt0{
    position: absolute;
    top: 30px;
    color: #4caf50;
    left: 320px;
  }
  .rt1{
    padding-left: 20px;
    width: 235px;
    height: 43px;
    border-radius: 8px;
    border: solid 1px #d0d5cd;
    box-shadow: inset rgba(192,192,192,.3) 2px 2px 3px ;
    background: #f2f2f2;
    display: flex;
    color: #999;
    align-items: center;
    outline: none;
    transition: .3s ease-in-out;
  }
  .cards{
    color: rgba(255, 255, 255, 0.7);
    height: 200px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    .sc{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: 0 23px;
      i{
        width: 30px;
        height: 30px;
        background: #fff;
        b{
          width: 30px;
          height: 30px;
          display: block;
          background:  url("./img/a.png") center no-repeat;
        }
        display: block;
        box-shadow: rgba(0, 0, 0, 0.2) 0 3px 10px -2px;
        opacity: .5;
        transition: .2s;
        border-radius: 50%;
        cursor: pointer;
        &:hover{
          opacity: 1;
          box-shadow: rgba(0, 0, 0, 0.3) 0 3px 13px -2px;
        }
        &.p b{
          transform: rotateZ(180deg);
        }
      }
    }
  }
  .ls{
    overflow: hidden;
    width: 560px;
    position: relative;
    height: 142px;
  }
  .bks{
    display: flex;
    align-items: center;
    left: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    transition: none;
    &.s{
      transition: .5s ease-in-out transform;
    }
    div{
      background: #53ae94 url("./img/bk.png") 10px 50% no-repeat;
      background-size: auto 35%;
      height: 50px;
      width: 216px;
      border-radius: 6px 6px 0 0;
      line-height: 50px;
      text-align: center;
      margin: 30px ;
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.2) 3px -10px 30px -6px;
      transition: .3s ease-in-out;
      &.act{
        background-color: #98c485;
        box-shadow: rgba(58, 161, 104, 0.2) 3px -10px 30px -6px;
      }
      &:hover{
        background-color: #81c44b;
        box-shadow: rgba(2, 160, 105, 0.2) 3px -10px 30px -6px;
      }
    }
    .ali{
      background: #6e91c6 url("./img/ali.png") 10px 50% no-repeat;
      background-size: auto 50%;
      &.act{
        background-color: #6990ca;
        box-shadow: rgba(58, 161, 104, 0.2) 3px -10px 30px -6px;
      }
      &:hover{
        background-color: #5295fa;
        box-shadow: rgba(2, 160, 105, 0.2) 3px -10px 30px -6px;
      }
    }
    .usdt{
      background-image: url("../../assets/svg/usdt.svg");
      background-size: auto 60% ;
      background-position: 9px center;
      background-repeat: no-repeat;
    }
  }
  .ctx{
    padding: 14px 80px;

    .tp {
      align-items: center;
      justify-items: center;
      height: auto;
      background: none;
      display: flex;
      flex-direction: column;
      p{
        font-size: 16px;
        line-height: 2;
      text-align: center;
        margin: 30px 0 0 0;
        padding: 20px;
        display: block;
        height: auto;
        width: 380px;
      }
      b{
        color: #0a94e3;
    }
    }

    .btn{
      margin-top: 50px;
      height: 50px;
      width: 300px;
    }
    .dis{
      pointer-events: none;
      background: #bbb;
      box-shadow: #bbb 0 5px 10px;
    }
    .ipt{
      span{
        padding-right: 5px;
        display: flex;
        align-items: center;
        i{
          background: url("./img/c.png");
          width: 18px;
          height: 18px;
          display: block;
        }
        top: -6px;
        left: 10px;
        color: #000;
        font-size: 12px;
        line-height: 19px;
        position: absolute;
        height: 19px;
        border-radius: 9px;
        background: #fff3f3;
      }
      position: relative;
      padding-top: 18px;
      p{
        display: flex;
        padding: 10px 20px;
        color: #c0c2bf;
        b{
          color: #0d9fff;
          font-weight: 400;
        }
        s{
          position: relative;
          top: -3px;
          left: -5px;
          display: block;
          background: url("./img/f.png");
          width: 23px;
          height: 22px;
        }
      }
    }
    input{
      &::placeholder{
        color: #ccc;
      }
      padding-left: 40px;
      width: 300px;
      height: 50px;
      border-radius: 4px;
      border: solid 1px #d0d5cd;
      background: #ffffff no-repeat 13px center;
      outline: none;
      transition: .3s ease-in-out;
      &:focus{
        border: solid 1px #0d9fff;
        box-shadow: inset 2px 2px 3px rgba(74, 143, 189, 0.3);
       +span{
         display: none;
       }
      }
    }
    .a{
      background-image: url("./img/a.svg");
    }
    .b{
      background-image: url("./img/b.svg");
    }
  }
  .my-card {
    height: 237px;
     background: linear-gradient(170deg,#fff,#f3f3f3);
    p{
      color: #bbb;
      padding:  0 76px;
    }
  }
}
