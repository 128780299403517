.bkPage {
  .ls {
    margin-top: 20px;
    margin-right: 20px;
    display: flex;
    flex-wrap: wrap;
    .fu {
      margin: 0;
    }
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 404px;
    height: 52px;
    background: #3270e1;
    border-radius: 6px;

    &.dis {
      background: #aaa;
      pointer-events: none;
    }
  }

  .tip {
    position: absolute;
    top: 27px;
    right: 20px;
    color: #133364;
    height: 24px;
    border-radius: 5px;
    background: #eef0f8;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 27px;
    padding: 0 18px;
  }

  .are {
    width: 98%;
    margin-bottom: 20px;
    min-height: 145px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    border: 1px solid #f2f2f2;

    .er {
      color: #ed6b77 !important;
    }

    & + .are {
      margin-top: -10px;
    }

    .r {
      padding: 0;
      flex-wrap: wrap;
    }

    .ipt {
      width: 100%;
      margin-bottom: 20px;
      position: relative;

      &.pv {
        width: 360px;

        .x-select {
          width: 100px;
        }
      }

      .d {
        color: #FD7373;
        font-size: 12px;
      }

      span {
        display: flex;
        position: absolute;
        white-space: nowrap;
        font-size: 12px;
        color: #FD7373;

        s {
          position: relative;
          left: -10px;
          top: -4px;
          background: url("../pop/img/f.png");
          width: 23px;
          height: 22px;
          display: block;
        }
        top: 50px;
        left: 100px;
      }

      input, .x-select {
        width: 300px;
        margin-left: 0;
        height: 50px;
        outline: none;
        padding: 0 17px;
        border-radius: 3px;
        border: solid 1px #d8dcd6;
        background: #ffffff;
        transition: .3s ease-in-out;
        box-shadow: none;

        &:focus {
          box-shadow: inset 2px 2px 3px rgba(88, 131, 175, 0.3);
          border: solid 1px #0d9fff;
        }
      }

      [readonly] {
        background: #f2f2f2;
        pointer-events: none;
      }

      input.err {
        border-color: #ed6b77;
      }

      .c {
        width: 288px;

        & + span {
          top: 55px;
          left: 90px;
        }
      }

      label {
        min-width: 70px;
        margin-right: 20px;

        &:before {
          content: '*';
          color: #FD7373;
          position: relative;
          top: 3px;
          padding-right: 3px;
        }

        &:after {
          content: ':';
          padding-left: 3px;
        }
      }

      display: flex;
      align-items: center;
    }

    .inf {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      padding: 10px 20px;
      position: relative;

      p {
        color: #999;
        font-size: 12px;
      }
    }

    .ico {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 120px;
      color: #636363;
      line-height: 3;

      i {
        background: url("../center/img/2.svg");
        width: 56px;
        height: 56px;
        display: block;
      }

      .b {
        background-image: url("../center/img/0.svg");
      }
    }
  }

}
