.notice-list{
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 300px;
  display: flex;
  max-height: 800px;
  flex-direction: column-reverse;
  z-index: 20;
}
.win-notice{
  min-height: 50px;
  border-radius: 5px;
  position: relative;
  transition: .4s ease-in-out,.2s transform,.2s box-shadow;
  width: 100%;
  background: #fff;
  margin: 8px 0;
  box-shadow: rgba(0,0,0,.1) -1px 3px 8px;
  overflow: hidden;
  opacity: 0;
  transform: translate3d(0,100%,0);
  max-height: 0;
  &.act{
    max-height: 300px;
    opacity: 1;
    transform: translate3d(0,0,0);
  }
  &:hover{
    transform: translate3d(0,-2px,6px);
    box-shadow: rgba(0,0,0,.15) -2px 0 16px;
    z-index: 90;
  }
  .t{
    padding:  10px 15px 0;
    color: #666;
    font-weight: 600;
    font-size: 14px;
    position: relative;
    height: 24px;
    line-height: 1.5;
    span{
      &:hover{
        opacity: 1;
      }
      opacity: .8;
      cursor: pointer;
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      color: #333;
      font-weight: normal;
      text-align: center;
      position: absolute;
      line-height: 1;
      right: 5px;
      top:5px;
      padding: 4px;
      font-size: 12px;
    }
  }
  .c{
    padding:  0 15px 10px;
    font-size: 14px;
    word-break: break-all;
    max-height: 200px;
    overflow: auto;
  }
}