.popup-win-msk{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  z-index: 1001;
  visibility: hidden;
  opacity: 0;
  transition: .3s ease-in-out;
  &.a,&.b{
    visibility: visible;
  }
  &.a.b{
    opacity: 1;
  }
  .dash-title{
    display: none;
  }
  .popup-win{
    background: #fff;
    overflow: hidden;
    width: 1000px;
    height: 710px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 10px 40px -14px;
    position: relative;
    &>.close{
      z-index: 12;
      box-shadow: inset  -1px 1px 3px rgba(0, 0, 0, 0.2);
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: 50%;
      font-size: 12px;
      background: #2d3f6e;
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
      &:hover{
        background: #4a72db;
      }
    }
  }
}
