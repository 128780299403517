.setting.popup-win-msk{
  .popup-win{
    border-radius: 5px;
    width: 466px;
    height: auto;
    min-height: 320px;
    display: flex;
    .close{
      right: 12px;
      top: 9px;
    }
    .ti{
      background: url("./img/n.jpg");
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }
  }
  .pop-pwd{
    flex: 1;
  }
}
