
.slideToWraper{
    height:30px;
    position: relative;
    overflow: hidden;
    font-size:12px;
    .smooth{
        transition: .3s ease-in-out;
    }
}
.slideToChild{
    height:30px;
    line-height: 30px;
    position: relative;
    &:before{
        content:'';
        width: 4px;
        height: 4px;
        background-color: #069e14;
        position: absolute;
        top:14px;
        left:-8px;
        border-radius: 50%;
    }
}
.slide_prize{
    color: #ecbc29;
}

.gr_p{
    height:100%;
}
.gr_c{
    width:100%;
}
.gr_p_2{
    height:100%;
}
.gr_c_2{
    width:100%;
}


