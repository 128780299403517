.x-table {
  font-size: 12px;
  width: 100%;
  border-collapse: collapse;
  .stu-h{
    display: flex;
    i{
      width: 8px;
      height: 8px;
      display: block;
      border-radius: 50%;
      background: #8cd964;
      margin-right: 4px;
      &+i{
        background: #377EF6;
        &+i{
          background: #FFB51B;
          &+i{
            background: #fff;
          }
        }
      }
    }
  }
  tbody{
    tr{
      &:hover{
        z-index: 20;
        background: #F6F6F6;
        position: relative;
      }
    }
  }
  thead {
    th{
      height: 40px;
      padding: 0;
      background: #e1e5ec;
      &:first-child{
        border-radius: 3px 0 0 3px;
      }
      &:last-child{
        border-radius:  0 5px 5px 0;
      }
      & > div {
        max-height: 40px;
      }
    }
  }

  th, td {
    height: 45px;
    text-align: center;
    border: none;
    & > div {
      display: inline-flex;
      align-items: center;
      height: 49px;
      justify-content: center;
    }
    .stu{
      height: 24px;
      min-width: 100px;
      background: rgba(0,0,0,.06);
      border-radius: 5px;
      padding:  0 8px;
      color: #666;
      &:not(.s-2){
        color: #fff;
      }
      &.s-1{
        background: #91C672;
        color: #2D7006;
      }
      &.s-0{
        background: #5E9AFE;
        color: #1E478B;
      }
      &.s-3{
        background: #FFB51B;
      }
    }
  }
}
