.confirm-box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  visibility: hidden;
  z-index: -1;
  pointer-events: none;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  transition: opacity .3s ease-in-out;

  &.act {
    visibility: visible;
    z-index: 9999;
    pointer-events: all;
  }

  .l {
    position: fixed;
    left: 150px;
    right: 150px;
    bottom: 150px;
    top: 150px;
  }

  .c {
    position: relative;
    z-index: 10;
    width: 400px;
     margin: 0 auto;
    .clo{
      opacity: .7;
      position: absolute;
      z-index: 10;
      right: 10px;
      top: 10px;
      width: 24px;
      height: 24px;
      cursor: pointer;
      transition: .2s ease-in-out;
      border-radius: 50%;
      background: url("./clo.svg") center no-repeat;
      background-size: 100% auto;
      &:hover{
        opacity: 1;
      }
    }
    .c-logo {
      border-radius: 4px 4px 0 0;
      background:#4062a3  url("../../pages/common/img/lo.svg") 20px 50% no-repeat;
      background-size:auto 50% ;
      width: 100%;
      height: 40px;
      display: block;
      position: relative;
      z-index: 3;
    }

    .t {
      user-select: none;
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      font-size: 14px;
      color: #666;
      text-align: center;

      i {
        position: absolute;
        right: 10px;
        top: 60px;
        font-style: normal;
        font-size: 12px;
        color: #999;
        cursor: pointer;

        &:hover {
          color: #333;
        }
      }

      & + .ctx {
        padding: 20px 10px 6px;
      }
    }

    .chk {
      label {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-top: 20px;
        flex: 1;
        font-size: 13px;
        min-width: 90px;
        color: #424242;

        input {
          margin-right: 4px;
        }
      }
    }

    .ctx {
      padding: 60px 0 0;
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, .4) 0 16px 50px -16px, inset rgba(0, 0, 0, 0.05) 0 2px 10px;
      font-size: 14px;
      text-align: center;
      color: #333;
      white-space: pre-wrap;
      width: 100%;
      word-break: break-all;
      display: flex;
      flex-direction: column;
      background: rgba(240, 241, 241, 0.9);
      backdrop-filter: blur(7px);
    }
  }
 .c-ctx{
   padding: 5px 20px 25px;
 }
  .mask {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1));
  }

  .btn {
    border-radius: 5px;
    overflow: hidden;
    margin: 30px auto 10px;
    width: 90%;
    display: flex;
    flex-direction: row-reverse;
    div {
      margin: 0 10px;
      flex: 1;
      height: 38px;
      &:hover {
        background: linear-gradient(90deg, #526eb9, #2755a9);
      }
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      cursor: pointer;
      background: linear-gradient(90deg, #3b59a7, #23437e);
      border-radius: 6px;
      color: #fff;
    }

    .n {
      background: linear-gradient(90deg, #d6d6d6, #bcc2c9);
      &:hover{
        background: linear-gradient(90deg, #d0d0d0, #b4b4b4);
      }
    }
  }
}
