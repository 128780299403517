.x-pg {
  height: 40px;
  align-items: center;
  display: flex;

  i {
    flex: 1;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pg-p {
    cursor: pointer;
    overflow: hidden;
    transition: .2s ease-in-out;
    width: 32px;
    margin: 0 3px;
    height: 32px;
    border-radius: 3px;
    position: relative;
    background: #e2e7f3;
    font-size: 12px;
    color: #999;
    border: 1px solid rgba(0, 0, 0, 0.05);
    &.pg-st,&.pg-en{
      width: 24px;
    }
   &:hover, &.act {
     color: #fff;
     background: #3270e1;
   }
    &.dis {
    pointer-events: none;
    opacity: .5;
  }
  }
  .pg-m{
     color: #eee;
    font-weight: 900;
    font-size: 20px;
    line-height: 1;
    padding-bottom: 5px;
  }
}
