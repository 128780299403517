.full-page.home {
  position: relative;
  .fb {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;

    p {
      justify-content: center;
      display: flex;
      align-items: center;
      text-align: center;
      flex: 1;
      font-size: 13px;
      color: #999;
    }

    i {
      display: block;
      height: 1px;
      width: 90px;
      background: #999;
      margin: 0 30px;
    }

    b {
      padding: 0 5px;
    }

    .c {
      margin: 0 auto;
      width: 1120px;
      display: flex;
      align-items: center;
      height: 48px;

      div {
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 127px;
        height: 38px;
        background: #4273e1;
        border-color: #4273e1;
        color: #fff;

        &:hover {
          background: #0a94e3;
        }
      }
    }

  }
 .dw{
   width: 1240px;
   height: 160px;
   margin: 30px auto;
   position: relative;
   background: url("./img/w.jpg") center no-repeat;
   background-size: contain;
  .qr{
    right: 30px;
    top: 15px;
    border-radius: 5px;
    position: absolute;
    width: 128px;
    height: 128px;
    background-size: contain;
  }
 }
  .btn {
    &:hover {
      background: #0a80c4;
    }

    cursor: pointer;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #2b44b1;
    border-color: #2b44b1;
    width: 320px;
    height: 50px;
    margin: 20px auto;
    max-height: 50px;
    line-height: 70px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 18px;
  }

  h1 {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    font-weight: 400;
    margin-top: 20px;

    i {
      background: url("./img/lo.svg") left center no-repeat;
      background-size: contain;
      display: block;
      height: 50px;
      width: 180px;
      margin-bottom: 15px;
    }

    div {
      font-size: 38px;
      line-height: 40px;
      margin-left: 20px;
      padding-left: 20px;
      height: 40px;
      border-left: 1px solid #fff;
    }

    color: #fff;
  }

  .cs {
    margin: 50px auto;
    display: flex;
    justify-content: space-between;
  }

  .cs3 {
    width: 1120px;

    p {
      font-size: 16px;
      line-height: 22px;
      color: #fff;
    }

    span {
      display: block;
      margin-top: 15px;
      color: #aaa;
    }

    i {
      display: block;
      width: 35px;
      height: 35px;
      position: absolute;
      left: 17px;
      top: -17px;
      background: no-repeat center;
      background-size: contain;
    }

    div {
      position: relative;
      flex: 1;
      height: 155px;
      border-radius: 10px;
      padding: 38px 31px 20px 28px;
      margin: 0 10px;
      box-sizing: border-box;
      background-color: rgba(0, 0, 0, 0.4);
    }

    .c0 i {
      background-image: url("./img/paopao.png");
    }

    .c1 i {
      background-image: url("./img/e1.png");
    }

    .c2 i {
      background-image: url("./img/e2.png");
    }
  }

  .cs2 {
    width: 745px;

    i {
      width: 54px;
      height: 54px;
      display: flex;
      overflow: hidden;
      border: 1px solid #0a94e3;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin: 0 auto;
      background: center no-repeat;
      background-size: 50%;
    }

    span {
      display: block;
      margin-top: 10px;
      text-align: center;
      color: #fff;
    }

    div {
      height: 84px;
    }

    .c0 {
      i {
        border-right-color: transparent;
        background-image: url("./img/c0.png");
      }
    }

    .c1 {
      i {
        border-top-color: transparent;
        background-image: url("./img/c1.png");
      }
    }

    .c2 {
      i {
        border-left-color: transparent;
        background-image: url("./img/c2.png");
      }
    }
  }

  .cs1 {
    width: 1120px;

    div {
      width: 280px;
      height: 119px;
      background-size: cover;
      border-radius: 10px;
    }

    .c0 {
      background-image: url("./img/b0.png");
    }

    .c1 {
      background-image: url("./img/b1.png");
    }

    .c2 {
      background-image: url("./img/b2.png");
    }
  }

  .hd {
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 3;
    width: 64px;
    height: 64px;

    i {
      margin: 0 auto;
      display: block;
      width: 30px;
      height: 30px;
      background: url("./img/0.svg") center no-repeat;
      background-size: 100% 100%;
    }

    span {
      display: block;
      margin: 5px auto;
      color: #fff;
      font-size: 13px;
      text-align: center;
    }
  }

  .src {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
   overflow: auto;
  }

  .hfp {
    padding: 1px 0 60px;
    position: relative;
    transition: .3s ease-in-out;
    background: url("./img/b3.jpg") center no-repeat;
    background-size: cover;
    overflow: hidden;
  }

  .h1 {
    background-image: url("./img/b4.png");
    .t0,p{
      opacity: 0;
      transform: translate3d(0,-100%,0);
      transition: .8s  ease-in-out;
    }
    p {
      display: block;
      margin: 10px auto;
      width: 490px;
    }
    i{
      display: block;
      width: 100%;
      flex: 1;
      background: center  bottom no-repeat;
      background-size: contain;
    }
    .r {
      width: 1175px;
      margin: auto;
      position: relative;
      .i {
        opacity: 0;
        transform: translate3d(0,100%,0);
        transition: .8s .5s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 206px;
        height: 206px;
        border-radius: 50%;
        background: white center no-repeat;
        background-size: contain;
        position: absolute;
        padding: 25px 0 30px;
        span {
          width: 49px;
          height: 49px;
          line-height: 49px;
          border-radius: 50%;
          position: absolute;
          background: #1e5adc;
          text-align: center;
          color: white;
          left: 50%;
          margin-left: -25px;
          top: -25px;
        }

        p {
          width: auto;
          font-size: 18px;
          font-weight: 400;
          color: #696b81;
          margin-top: 10px;
        }
      }

      .a1 {
        left: 20px;
        top: 230px;

        i {
          background-image: url("./img/q0.png");
        }
      }

      .a2 {
        left: 211px;
        bottom: -82px;

        i {
          background-image: url("./img/q2.png");
        }
      }

      .a3 {
        right: 204px;
        bottom: -82px;

        i {
          background-image: url("./img/q3.png");
        }
      }

      .a4 {
        right: 0;
        top: 232px;

        i {
          background-image: url("./img/q4.png");
        }
      }
    }

    .a0 {
      opacity: 0;
      transition: .8s .2s ease-in-out;
      background-image: url("./img/b5.png");
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      height: 536px;
      overflow: hidden;
      width: 393px;
      margin: 30px auto 0;
    }

    .t0 {
      position: relative;
      width: 400px;
      margin: 50px auto 30px;
      height: 50px;
      font-size: 42px;
      font-weight: 400;
      color: #202243;

      div {
        position: absolute;
        left: 0;
        right: 0;
        height: 1px;
        background: #1e5adc;

        i {
          position: absolute;
          width: 77px;
          height: 5px;
          border-radius: 2px;
          background: #1e5adc;
        }
      }
    }
    &.ac{
      .t0,p{
        opacity: 1;
        transform: translate3d(0,0%,0);
      }
      .a0{
        opacity: 1;
      }
      .r{
        .i{
          opacity: 1;
          transform: translate3d(0,0%,0);
        }
      }
    }
  }
}
