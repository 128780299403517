.video-player {
   width: 100%;
  height: 100%;
  background: #000;
  position: relative;
  cursor: pointer;
  div{
    overflow: hidden;
    transition: .3s ease-in-out;
    width: 100%;
    height: 100%;
  }
  .ico-play{
    pointer-events: none;
    opacity: 0;
    width: 140px;
    height: 140px;
  }
  &.pu{
    &:hover{
      div{
        opacity: .8;
      }
    }
    div{
      opacity: .7;
    }
    .ico-play{
      opacity: .6;
    }
  }
  video{
    width: 100%;
    height: 100%;
  }
}
.ico-play {
  opacity: .5;
  transition: .3s ease-in-out;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%,-50%,0);
  background: url("./img/pl.svg") no-repeat center;
  background-size: 100% 100%;
  width: 60px;
  height: 80px;
  display: block;
}
.pop-video {
  .popup-win {
    background: rgba(68, 187, 255, 0.4);
    box-shadow: rgba(0, 0, 0, 0.3) 0 20px 40px -14px;
    border-radius: 6px;
    width: 720px;
    height: 409px;
    padding: 4px;
  }
}
