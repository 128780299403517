@for $i from 0 through 29 {
  .ava-#{$i}{
    background-image: url("./img/av/#{$i}.png")!important;
  }
}
.ava.popup-win-msk {
  .t{
    margin: 20px auto 0;
    text-align: center;
  }
  .btn{
    width: 112px;
    height: 33.59px;
    border-radius: 4px;
    background: linear-gradient(27deg, #56B7FF, #2B81FF);
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:  20px auto;
    font-size: 13px;
    opacity: .9;
    &:hover{
      opacity: 1;
    }
  }
  .ls{
    margin:  0 auto;
    display: flex;
    width: 560px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    div{
      cursor: pointer;
      width: 88px;
      height: 62px;
      background: center no-repeat;
      margin-bottom: 7px;
      transition: .2s ease-in-out;
      border-radius: 5px;
      background-size: contain;
      &:hover{
        transform: translate3d(0,-2px,1px);
        box-shadow: rgba(0, 0, 0, 0.1) 0 2px 5px;
      }
    }
    .act{
      background-color: #eef0f4;
    }
  }
  .popup-win {
    width: 600px;
    height: 490px;
    border-radius: 6px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(142, 142, 142, 0.5);

    .close {
      background: none;
      box-shadow: none;
      color: #aaa;
      font-size: 14px;
      top: 5px;
      right: 8px;
      &:hover{
        color: #ed6b77;
      }
    }
  }

  .ava-ls {

  }
}
