@import "../../styles/util";
.x-select{
  position: relative;
  width: 120px;
  z-index: 2;
  &:hover{
    &:after{
      opacity: .9;
    }
  }
  &:after {
    pointer-events: none;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    border-radius:  0 4px 4px 0;
    content: '^';
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    width: 24px;
    overflow: hidden;
    transform: rotateZ(180deg);
  }
  &.on{
    z-index: 10;
    box-shadow: inset rgba(9, 131, 255, 0.3) 0 1px 3px, rgba(25, 176, 255, 0.2) 0 1px 5px;
    &:before{
      border: 6px solid transparent;
      border-bottom-color: #fff;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      content: '';
      display: block;
      bottom: -6px;
      z-index: 100;
    }
  }
  margin: 0 4px;
  border: 1px  solid rgba(0,0,0,.05);
  border-radius: 3px;
  &>div:first-child{
    width: 100%;
  }
  div{
    white-space: nowrap;
    color: #333;
    padding: 0 5px;
    line-height: 24px;
    height: 24px;
    cursor: pointer;
  }
  .opts{
    position: absolute;
    margin-top: 6px;
    top:100%;
    left: -1px;
    right: -1px;
    min-width: 100px;
    background: #fff;
    border-radius: 5px;
    height: auto;
    overflow: auto;
    box-shadow: rgba(0, 0, 0, 0.1) 2px -2px 20px ;
    max-height: 300px;
    padding: 0;
    display: none;
    &.act{
      display: block;
    }
    div{
      color: #666;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      &+div{
        border-top: 1px solid rgba(255, 255, 255, 0.5);
      }
      &:hover,&.s{
        background: #8BCDFF;
        color: #fff;
      }
    }
    .s{
      background: #eee;
    }
  }
}
.x-select.select{
  background: #fff;
  font-size: 13px;
  width: 160px;
  border-radius: 2px;
  border-color: #c1bbc7;
  &:hover{
    box-shadow: 0 0 2px #26B999;
  }
  .opts{
    max-height: 200px;
    overflow: auto;
    border-radius: 0 0 2px 2px;
    border-color: #c1bbc7;
    margin-top:4px;
    &:hover{
      box-shadow: 0 0 2px #26B999;
    }
    div{
      border: 0;
    }
  }
}
