.feePage{
  padding-top: 50px;
  position: relative;
  .sel{
    width: 200px;
    display: flex;
    align-items: center;
    div{
      text-align: center;
    }
  }
   .r{
     display: flex;
     align-items: center;
     justify-content: space-between;
   }
  .tabs{
    display: flex;
    div{
      font-size: 14px;
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      background: #f2f2f2;
      cursor: pointer;
      border-radius: 3px;
      margin-right: 10px;
      opacity: .8;
      &:hover{
        opacity: 1;
      }
    }
    .act{
      opacity: 1;
      background: rgb(43, 68, 177);
      color: #fff;
    }
  }
}