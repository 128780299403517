.pp-s {
  position: absolute;
  transform: translate3d(0, 0, 0);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  opacity: 0;
 .br{
   .b0 span{
     display: none;
   }
 }
  .rate-cfg{
    background: #f8f8f8;
    padding: 10px 20px;
  }
  .ss {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &.act {
    opacity: 1;
  }

  .withdraw_prompt {
    margin: 0;
    width: 100%;
  }

  .dM_text {
    margin-right: 50px;
  }

  .drawMoney {
    margin: 0;
    .r {
      margin-bottom: 10px;
    }
  }
  .btn{
    margin:  20px;
  }
}
.pp-c{
  background: #F5F5F5;
  .rate-cfg{
    margin: 30px auto;
    width: 70%;
  }
}
.pp-f{
  display: flex;
  align-items: center;
  justify-content: center;
  .btn{
    width: 212px;
    border-radius: 4px;
  }
}
.pp-p {
  background: #fff;
  height: 100%;
  border-radius: 5px;

  .pp-t {
    display: flex;
    padding: 20px 15px 10px;
    align-items: center;
    font-size: 13px;
    .bn{
      color: #fff;
      background: #1e5adc;
      padding:  2px 10px;
      border-radius: 4px;
      font-size: 13px;
      margin: 0 20px;
    }
    i {
      margin-right: 5px;
    }
  }
}
.pp-d{
  padding: 50px 30px;
  p{
    margin-bottom: 10px;
    color: #999;
    font-size: 12px;
  }
  label{
    font-size: 16px;
  }
  b{
    color: #00a0fe;
    padding:  0 4px;
  }
  input{
    border-radius: 3px;
    height: 40px;
    padding: 0 10px;
  }
  p{
    line-height: 3;
  }
}
.dividend-cfg{
  position: relative;
  height: 90%;
  margin: 10px;
  .br {
    display: flex;
    height: 51px;
    margin-bottom: 10px;
    background: #fff;
    align-items: center;

    .btn {
      width: 67px;
      height: 30px;
      margin: 0 10px;
    }
  }

  .b0 {
    align-items: center;
    display: flex;
    flex: 1;
    padding-right: 10px;
   label{
     display: flex;
     flex-direction: column;
     align-items: center;
   }
    s {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: url(../account/img/b.png) center no-repeat;
      margin: 0 5px;
    }
  p{
    display: flex;
    align-items: center;
  }
    b{
      &:before{
        transition: .1s;
        opacity: 0;
        position: absolute;
        left: 0;
        right: 0;
         display: flex;
        align-items: center;
        font-size: 11px;
        justify-content: center;
        border-radius: 50%;
        content: '√';
        color: #fff;
        top: 0;
        bottom: 0;
        background: #377ef6;
      }
      &.act:before{
        opacity: 1;
      }
      width: 20px;
      height: 20px;
      display: flex;
      position: relative;
      border-radius: 50%;
      cursor: pointer;
     margin-right: 10px;
      background: #f5f5f5;
      border: 1px solid #ccc;
    }
    span {
      font-size: 10px;
      color: #999;
    }

    i {
      background: #0567d2 url("../account/img/g.png") center no-repeat;
      width: 36px;
      height: 36px;
      border-radius: 0 04px 4px 0;
      margin-right: 10px;
    }
  }
  .ls {
    margin: -10px 0 10px;
    padding: 1px 0 10px;
    .k {
      position: relative;
      padding: 0 0 0 10px;
      display: flex;
      justify-content: space-between;
      height: 50px;
      margin-top: 10px;
      align-items: center;
      background: #fff;
        .msk{
          position: absolute;
          left: 0;
          right: 0;
          top:0;
          bottom: 0;
          background: rgba(255, 255, 255, 0.2);
        }
      i {
        width: 31px;
        height: 31px;
        font-style: normal;
        border: 1px solid currentColor;
        color: #e6e6e6;
        cursor: pointer;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          color: red;
        }
      }

      span {
        left: 1px;
        right: 1px;
        bottom: 1px;
        top: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        color: orange;
        position: absolute;
        pointer-events: none;
      }

      input {
        border: 0;
        box-shadow: none;
        outline: none;
        height: 31px;
        width: 100%;
        text-align: center;
        padding: 0 10px;
      }

      div {
        position: relative;
        overflow: hidden;
        text-align: center;
        border-radius: 5px;
        flex: 1;
        margin:  0 10px;
        &.v{
          border: 1px solid #e6e6e6;
        }
      }
      .btn{
        flex: none;
        height: 30px;
        width: 73px;
        &.none{
          visibility: hidden;
        }
        &.del{
           background: #999;
          box-shadow: inset #999 0 0 10px, #999 0 4px 14px -6px;
        }
      }
      .er {
        border-color: red;
      }
    }
  }
}
