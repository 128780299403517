.tBetPage {
  .inf {
    flex: 1;
    color: #133364!important;
    right: -20px !important;
    top: 10px !important;
    height: 24px;
    border-radius: 5px;
    background: #eef0f8;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 27px;
    padding: 0 18px;
  }

  .i-dt {
    width: 20px;
    height: 20px;
    border: 1px solid currentColor;
    border-radius: 50%;
    color: #B4B4B4;
    cursor: pointer;
    align-items: center;
    padding-bottom: 5px;

    &:hover {
      color: #EA8C7C;
    }
  }

  .pop-up {
    min-height: 640px;
    position: relative;
    padding: 10px;
    z-index: 10;
    background: #fff;

    & > .bn {
      float: right;
    }

    &.act {
      display: block;
    }

    .ctx {
      .hi {
        visibility: hidden;
      }

      width: 720px;
      margin: 0 auto;
    }
  }

  .l-tbs {
    top: 70px;
    right: 30px;
    position: absolute;
    display: flex;
    border: 1px solid #DAE4F1;
    border-radius: 3px;

    div {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 92px;
      height: 35px;
      color: #BACBE1;
      cursor: pointer;

      &:hover {
        color: #333;
        background: #f1f2ff;
      }

      + div {
        border-radius: 0 4px 4px 0;
        border-left: 1px solid #DAE4F1;
      }

      &.act {
        border-color: transparent;
        color: #fff;
        background: #1e5adc;
      }
    }

  }
}
